const videoPopupBtn = document.querySelector('.video-play-btn');
const videoSourceElement = document.querySelector('#player-1 source');
const videoPopup = document.querySelector('.video-popup');
const videoPopupClose = document.querySelector('.video-popup-close');
if (videoPopupBtn && videoSourceElement) {
    const videoSource = videoSourceElement.getAttribute('src');
    videoPopupBtn.addEventListener('click', (event) => {
        if (videoPopup) {
            if (videoPopup.classList.contains('open')) {
                videoPopup.classList.remove('open');
                videoSourceElement.setAttribute('src', '');
            } else {
                videoPopup.classList.add('open');
                if (videoSourceElement.getAttribute('src') == '') {
                    videoSourceElement.setAttribute('src', videoSource);
                }
            }
        }
    });
    videoPopup.addEventListener('click', (event) => {
        if (videoPopup) {
            if (videoPopup.classList.contains('open')) {
                videoPopup.classList.remove('open');
                videoSourceElement.setAttribute('src', '');
            } else {
                videoPopup.classList.add('open');
                if (videoSourceElement.getAttribute('src') == '') {
                    videoSourceElement.setAttribute('src', videoSource);
                }
            }
        }
    });
}
